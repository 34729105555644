@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.info-news-list {
  margin: 3px 0 0;
  padding: 0;
  width: 100%;
  @include sys.media(lg) {
    margin: 16px 0 0;
  }
}

.news-container {
  border-bottom: solid 1px g.$border-color;
  &:last-child {
    margin-bottom: 0;
  }

  @media (hover: hover) {
    &:hover {
      background-color: g.$light-gray;
      color: g.$base;
    }
  }

  .news-body {
    width: 100%;
    padding: 1em 0;
  }

  .news-anchor {
    padding: 14.5px 4px;
    position: relative;
    transition: all g.$base-transition;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sys.media(lg) {
      flex-wrap: nowrap;
      padding: 28px 8px;
    }
  }

  .date {
    color: g.$subtle;
    font-size: 1.4rem;
    white-space: nowrap;
  }

  .category,
  .news-category {
    margin-left: 12px;
    @include sys.media(lg) {
      min-width: 114px;
      margin-left: 23px;
    }

    .category-label,
    .category-item {
      background-color: g.$bg-default;
      border-radius: 999px;
      display: block;
      font-size: 1.1rem;
      padding: 5px 10px;
      text-align: center;
      white-space: nowrap;
      width: 100%;
      @include sys.media(lg) {
        min-width: 114px;
        padding: 4px 10px;
      }
      /*
      &.patient {
        background-color: g.$secondary;
        color: g.$white;
      }
      &.ordinary {
        background-color: g.$primary;
        color: g.$white;
      }
      &.participants {
        background-color: g.$primary;
        color: g.$white;
      }
      */
    }
  }
  .title {
    display: block;
    width: 100%;
    position: relative;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include sys.media(lg) {
      font-size: 1.6rem;
      margin-left: 24px;
      letter-spacing: 0.1em;
    }
  }
}

.news-post-date {
  display: flex;
  align-items: center;
  position: relative;
  color: g.$gray-40;
  font-weight: bold;
  font-size: 0.875em;
  margin-bottom: 2em;
  &::before {
    content: g.$calendar;
    font-family: g.$icon-font-family;
    color: g.$gray-40;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    transition: opacity g.$base-transition;
  }
}

.pagination-container {
  margin-top: 28px;
}
