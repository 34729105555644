@charset 'UTF-8';

@use '../global/' as *;

// variable
$modal-active-class-name: '.active';

.modal-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  opacity: 0;
  visibility: hidden;
  transition: opacity $base-transition;
  z-index: 9999;
  &#{$modal-active-class-name} {
    opacity: 1;
    visibility: visible;
  }
}

.modal-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 90%;
  max-width: 690px;
  max-height: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  background-color: $white;
  overflow: auto;
  @include media(md) {
    max-height: 286px;
    padding: 32px;
  }
}

.js-modal-close {
  position: absolute;
  top: -30px;
  right: -10px;
  color: $white;
  @include fontsize(16);
  cursor: pointer;
  @include media(md) {
    right: -30px;
    @include fontsize(20);
  }
  @include media(lg) {
    top: -50px;
    font-size: 3rem;
  }
}
