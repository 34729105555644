@charset 'UTF-8';
@use '../global/' as *;

.timeline {
  &-item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 28px;
      @include media(lg) {
        margin-bottom: 37px;
      }
    }
    &:last-child {
      .timeline {
        &-time {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  &-time {
    margin: 0 12px 0 0;
    padding: 0 20px 0 0;
    min-width: 76px;
    position: relative;
    color: $primary;
    font-weight: bold;
    font-size: 1.5rem;
    box-sizing: border-box;
    @include media(lg) {
      margin-right: 0;
      @include fontsize(16);
      min-width: 102px;
    }
    &::after,
    &::before {
      position: absolute;
      right: 5px;
      top: 4px;
      content: '';
      @include media(lg) {
        top: 5px;
        right: 18px;
      }
    }
    &::before {
      width: 1px;
      right: 15px;
      margin: 15px auto;
      top: 15px;
      bottom: -38px;
      background-color: $border-color;
      @include media(lg) {
        right: 28px;
      }
    }
    &::after {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 6px solid $bg-highlight;
      background-color: $secondary;
    }
  }
  &-inner {
    flex: 1;
    @include media(lg) {
      margin-top: 2px;
    }
  }
  &-title {
    color: $base;
    margin: 0 0 12px;
    padding: 0;
    position: static;
    font-weight: bold;
    @include fontsize(16);
    border: none;
    border-radius: 0;
    @include media(lg) {
      margin: 0 0 8px;
      @include fontsize(18);
    }
    &::before {
      display: none;
    }
  }
  &-content {
    margin: 0;
  }
}
