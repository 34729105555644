@charset 'UTF-8';

@use '../global/' as *;

.table {
  width: 100%;
  margin: 24px auto;
  border-collapse: collapse;
  thead {
    th {
      color: $white;
      background-color: $primary-30;
    }
  }
  th,
  td {
    padding: 14px 12px;
    font-weight: normal;
    text-align: left;
    border: 1px solid $table-border-color;
  }
  th {
    background-color: $table-th-background-color;
  }
  td {
    background-color: $table-td-background-color;
    overflow-wrap: break-word;
  }
  tr {
    & > *:empty {
      background-image: linear-gradient(to right top, transparent calc(50% - 0.5px), $border-color 50%, $border-color calc(50% + 0.5px), transparent calc(50% + 1px));
    }
  }
}

// スクロールを出すための wrapper
.table-scroll-wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(lg) {
    width: 100%;
    white-space: normal;
    margin: 0 auto;
    padding: 0;
  }
  .table {
    margin: 0 auto 24px;
  }
}

.table-overview {
  margin: 0 calc(50% - 50vw);
  padding: 0 28px;
  @include media(lg) {
    width: 100%;
    white-space: normal;
    margin: 0 auto;
    padding: 0;
  }
}

.table-responsive {
  width: 100%;
  border: none;
  & > thead {
    display: none;
    @include media(lg) {
      display: table-row-group;
    }
  }
  & > thead + tbody {
    & > tr {
      & > td {
        &::before {
          content: attr(data-label);
          background-color: $bg-default;
          border-radius: 999px;
          display: inline-block;
          font-size: 1.1rem;
          margin-right: 10px;
          padding: 5px 10px;
          text-align: center;
          white-space: nowrap;
          width: 3em;
          @include media(lg) {
            display: none;
          }
        }
      }
    }
  }
  & > tbody {
    & > tr {
      &:last-child {
        @include media(lg) {
          border-bottom: none;
        }
      }
      & > th,
      & > td {
        width: 100%;
        display: block;
        border: solid 1px $table-border-color;
        border-bottom: none;
        @include media(lg) {
          width: auto;
          display: table-cell;
          border-bottom: 1px solid $table-border-color;
        }
      }
      & > th {
        border-bottom: none;
        @include media(lg) {
          width: 30%;
          border-bottom: solid 1px $table-border-color;
        }
      }
      & > td {
        &:last-child {
          margin-bottom: 20px;
          border-bottom: solid 1px $table-border-color;
          @include media(lg) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

//th_中央寄せ
.table_thcenter {
  th {
    text-align: center;
  }
}

//td_中央寄せ
.table_tdcenter {
  td {
    text-align: center;
  }
}

.table-layout-fixed {
  width: max-content;
  max-width: 100%;
  table-layout: fixed;
}
