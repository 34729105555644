@charset 'UTF-8';

@use '../global/' as *;

:root {
  // root font size
  @each $key, $size in $root-font-size {
    @include media($key, true) {
      --root-font-size: #{$size};
    }
  }

  // grid
  @each $key, $value in $breakpoints {
    @include media($key, true) {
      --grid-gutter: #{map-get($grid-gutters, $key)};
      --form-grid-gutter: #{map-get($form-grid-gutters, $key)};
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

i {
  font-style: italic;
  line-height: inherit;
}

html {
  font-size: 62.5%;
  //font-size: var(--root-font-size);
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html[lang='ja'] {
  font-family: $base-font-family;
}

html[lang='en'] {
  font-family: $base-font-family-en;
}

body {
  background-color: $base-background-color;
  color: $base-text-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  min-width: 100%;
  text-rendering: optimizeLegibility;
  letter-spacing: $base-letter-spacing;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  // フォントレンダリング設定
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  // フォントレンダリング設定 - Retina Display
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @include media(lg) {
    @include fontsize(16);
  }
}

// Links
a {
  color: $base;
  text-decoration: $base-link-decoration;
  @media (hover: hover) {
    &:hover {
      color: $base-link-hover-color;
      text-decoration: $base-link-hover-decoration;
    }
  }
}

// Images
img {
  border-style: none;
  display: block;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
}

// Horizontal line
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: $hr-y-gutter 0;
  width: 100%;
  height: 0;
}

// List
ul,
ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

// Table
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}

// Form & Button
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

button:-moz-focusring,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
  font-family: inherit;
}

button,
select {
  text-transform: none;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  border: 0;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

:focus:not(:focus-visible) {
  outline: none;
}
