@charset 'UTF-8';
@use './global/' as *;

a {
  transition: opacity $base-transition;
  word-wrap: break-word;
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  &.inline {
    color: $link-color;
    text-decoration: solid;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &[href*='tel:'] {
    @include media(lg) {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }
  &.block {
    display: block;
  }
}

hr {
  margin: 32px auto;
  border-color: $border-color;
  &.hr-dashed {
    border-style: dashed;
  }
}

//モバイル非表示
.pc {
  display: none !important;
  @include media(lg) {
    display: block !important;
  }
}

//PC非表示
.md {
  @include media(lg) {
    display: none !important;
  }
}

// Photo Layout
.common-photo {
  .photo {
    max-width: 640px;
    margin: 0 auto;
    &:first-child {
      margin-bottom: 16px;
      @include media(md) {
        margin-bottom: 18px;
      }
    }
    .photo-inner {
      margin-bottom: 10px;
      @include media(md) {
        margin-bottom: 14px;
      }
    }
    .photo-note {
      @include fontsize(14);
      @include media(md) {
        text-align: left;
      }
    }
    img {
      max-width: 100%;
      margin: 0 auto;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .text {
    flex: 1;
    &:first-child {
      margin-bottom: 16px;
    }
  }

  &.photo-right,
  &.photo-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media(md) {
      gap: 20px;
      flex-direction: row;
    }
    .photo {
      max-width: 40%;
    }
    &.photo-reverse {
      flex-direction: column-reverse;
    }
  }
}
.photo-float {
  overflow: hidden;
  &-item {
    float: left;
    margin: 0 20px 20px 0;
    @include media(md) {
      width: 30%;
    }
  }
}

.img-responsive {
  width: 100%;
  max-width: max-content;
  height: auto;
  display: block;
  margin: 0 auto;
  @include media(md) {
    max-width: 100%;
  }
}

// Box
.border-box {
  margin: 20px auto;
  border: 1px solid $border-color;
  @each $name, $color in $all-colors {
    &--#{$name} {
      border-color: $color;
    }
  }
}

.color-box {
  margin: 20px auto;
  background-color: $gray-90;
  border-radius: 8px;
  @each $name, $color in $all-colors {
    &--#{$name} {
      background-color: $color;
    }
  }
}

.border-box,
.color-box {
  padding: 24px 20px;
  border-radius: 8px;
  @include media(lg) {
    padding: 40px;
  }
  .heading {
    margin-bottom: 12px;
    @include media(lg) {
      margin-bottom: 16px;
    }
  }
  &--contact {
    text-align: center;
  }
}

// Maker
.marker {
  background-image: linear-gradient(90deg, $maker-color 90%, transparent);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: auto 1.3em;
  padding: 0 0.25em;
}

.marker-animation {
  padding: 0 0.25em;
  &.is-visible {
    background-image: linear-gradient(90deg, $maker-color 90%, transparent);
    background-repeat: no-repeat;
    background-size: 200% 1.3em;
    /*2つ目の値でマーカーの太さを変える*/
    background-position: 0% bottom;
    animation: marker 3s ease 0s;
    /*マーカーを引く速度*/
  }
}

@keyframes marker {
  0% {
    background-position: right -100% bottom;
  }
  100% {
    background-position: 0% bottom;
  }
}

// 注釈
.indent {
  padding-left: 1em;
  text-indent: -1em;
}

// カード
.card {
  &__link {
    padding: 20px 24px 0;
    display: block;
    border-radius: 8px;
    border: 1px solid $border-color;
    overflow: hidden;
    @include media(lg) {
      padding: 36px 40px 0;
    }
    @media (hover: hover) {
      &:hover {
        background-color: $bg-hovered;
      }
    }
    &.max-height {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card__content {
        flex: 1;
      }
    }
  }
  &__photo {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__more {
    margin-top: 16px;
    border-top: 1px solid $border-color;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    @include media(lg) {
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 36px;
    }
    &::after {
      content: $arrow-right;
      font-family: $icon-font-family;
      color: $primary;
      font-size: 1.8rem;
      @include media(lg) {
        margin-right: 3px;
      }
    }
  }
  &__title {
    margin-bottom: 12px;
    @include fontsize(18);
    font-weight: normal;
  }
  &--image {
    .card__link {
      padding: 0;
    }
    .card__content {
      padding: 10px 18px;
    }
    .card__more {
      margin-top: 0;
      padding-left: 18px;
      padding-right: 18px;
      @include media(lg) {
        padding: 20px 24px;
      }
      &::after {
        right: 18px;
      }
    }
  }
}

// アンカーリンク
.contents-list {
  margin-bottom: 40px;
  padding: 0 40px;
  border-left: 1px solid $border-color;
  @include media(lg) {
    margin-bottom: 60px;
  }
  a {
    color: $subtle;
    text-decoration: underline;
  }
  .title {
    margin-bottom: 18px;
    @include fontsize(14);
  }
  li {
    line-height: inherit;
    padding-left: 1.4em;
    position: relative;
    &::before {
      width: 8px;
      height: 8px;
      background: $subtle;
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 11px;
      content: '';
    }
    & + li {
      margin-top: 12px;
    }
  }
}

// ページタイトル
.page-hero {
  padding: 0 6px;
  .page-hero-inner {
    position: relative;
  }
  &-picture {
    height: 76vw;
    max-height: 296px;
    position: relative;
    display: block;
    border-radius: 16px;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      width: 433.5px;
      height: 433.5px;
      left: -177px;
      top: -27.5px;
      background: linear-gradient(218.79deg, rgba(247, 241, 76, 0.28) 18.44%, rgba(252, 103, 4, 0.8) 56.85%);
      filter: blur(100px);
      transform: translateZ(0);
      z-index: 2;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: $overlay-on;
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    margin: 0 !important;
    padding: 0 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include fontsize(29);
    font-weight: bold;
    color: $white;
    z-index: 2;
  }

  @include media(lg) {
    padding: 0 16px;
    &-picture {
      height: 30.5vw;
      max-height: 438px;
      &::before {
        width: 773px;
        height: 773px;
        left: -177px;
        top: -39.5px;
        background: linear-gradient(218.79deg, rgba(247, 241, 76, 0.28) 18.44%, rgba(252, 103, 4, 0.8) 56.85%);
      }
    }
    &-title {
      padding: 0 80px;
      @include fontsize(46);
    }
  }
}

// swich
.swich-checkbox {
  display: none;
  &:checked {
    & + .swich-container {
      &::before {
        left: 51%;
      }
    }
  }
}

.swich-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: $natural-90;
  border: 2px solid $natural-90;
  @include border-radius(sm);
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    width: 49%;
    height: 100%;
    left: 0%;
    @include border-radius(sm);
    background: $white;
    transition: left $base-transition;
  }
  div {
    padding: 7px 8.5px;
    text-align: center;
    z-index: 1;
  }
}

// contact-box
.contact-box {
  padding: 24px 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include media(lg) {
    padding: 30px 30px 36px;
  }
  .heading {
    margin: 0;
    font-weight: bold;
    @include media(lg) {
      @include fontsize(29);
    }
  }
  .contact-box-tel {
    &__title {
      color: $subtle;
      font-size: 1.4rem;
      line-height: 1.5;
      @include media(lg) {
        font-size: 1.6rem;
      }
    }
    &__list {
      font-weight: bold;
      font-family: $base-font-family-en;
      @include fontsize(23);
      li {
        & + li {
          margin-top: 4px;
        }
      }
      @include media(lg) {
        @include fontsize(29);
      }
    }
  }
  .contact-box-address {
    @include media(lg) {
      @include fontsize(18);
    }
  }
  .contact-box-email {
    width: 218px;
    margin: 0 auto;
    @include media(lg) {
      width: 100%;
      .btn {
        width: 238px;
      }
    }
  }
  .contact-box-other {
    margin-top: 16px;
    padding-top: 32px;
    border-top: 1px solid $border-color;
  }
}

.video {
  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}

.float-container {
  .float-left,
  .float-right {
    margin-bottom: 20px;
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  @include media(md) {
    overflow: hidden;
    .float-left {
      float: left;
      margin-right: 20px;
    }
    .float-right {
      float: right;
      margin-left: 20px;
    }
  }
}

// smartphoto
.smartphoto-content {
  background-color: $white;
}

.table-child {
  width: 100%;
  th,
  td {
    border: none;
  }
}

.flexbox {
  display: flex;
  gap: 20px;
}
