@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.preview {
  background-color: #eef2f6;
  padding-bottom: 60px;
}

.preview-header {
  background-color: g.$white;
  margin-bottom: 60px;
  padding: 10px 0;
  text-align: center;
}

.preview-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-headline {
  font-size: 2rem;
  font-weight: bold;
}

.preview-block {
  background-color: g.$white;
  border-radius: 8px;
  padding: 20px;
  & + & {
    margin-top: 40px;
  }
}

.preview-headline {
  border-bottom: 1px solid g.$gray-70;
  color: g.$gray-20;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1em;
}
