@charset 'UTF-8';

@use '../global/' as *;

// variables
$tab-button: '.js-btn';
$tab-current-class-name: '.js-tab-current';
$tab-current-view-class-name: '.js-tab-current-view';

.tab-wrapper {
  width: 100%;
}

.tab-label-wrap {
  width: 100vw;
  // TODO: betu
  margin: 0 calc(50% - 50vw);
  padding: 0 20px;
  overflow: auto;
  @include media(md) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

ul.tab-label {
  width: max-content;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  border-right: 1px solid $border-color;
  li {
    width: 100%;
  }
}

.tab-label-item {
  width: 100%;
  padding: 12px;
  text-align: center;
  border-top: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  background-color: $bg-default;
  transition: background-color $base-transition;
  white-space: nowrap;
  @include fontsize(14);
  @include media(lg) {
    padding: 16px;
    @include fontsize(16);
  }
  @media (hover: hover) {
    &:hover {
      background-color: $bg-hovered;
    }
  }

  &#{$tab-current-class-name} {
    background-color: $white;
    border-bottom-color: $white;
  }
}

.tab-contents {
}

.tab-contents-item {
  display: none;

  &#{$tab-current-view-class-name} {
    display: block;
    animation: show 0.4s linear 0s;
  }
}

.top-btn-list {
  margin-top: 20px;
  text-align: center;
  @include media(lg) {
    margin-top: 34px;
  }
}
