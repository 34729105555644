@charset "UTF-8";

@use '../global/' as *;

.sidebar {
  width: 100%;
  margin-top: 48px;
  background-color: $primary-98;
  @include media(lg) {
    width: 240px;
    margin-top: 0;
    background-color: transparent;
  }
}

.sidebar-title {
  margin-bottom: 16px;
  padding: 25px 20px;
  border-bottom: 1px solid $border-color;
  font-weight: bold;
  @include fontsize(18);
  display: flex;
  align-items: center;
  gap: 10px;
  @include media(lg) {
    padding: 0 0 24px;
    white-space: nowrap;
  }
  &::before {
    content: $arrow-right;
    border-radius: 50%;
    background-color: $secondary;
    font-family: $icon-font-family;
    color: $white;
    font-size: 1.6rem;
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  a {
    flex: 1;
  }
}

.sidebar-inner {
  padding: 0 20px;
  @include media(lg) {
    padding: 0;
  }
}

.sidebar-list {
  padding-bottom: 25px;
  @include fontsize(14);
  @include media(lg) {
    padding-bottom: 38px;
  }
  &__item {
    &--second {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-right: 6px;
      a {
        width: 100%;
        padding: 13px 5px;
        flex: 1;
      }
      i {
        cursor: pointer;
        font-size: 16px;
      }
    }
    &.active {
      i::after {
        content: $minus;
        font-family: $icon-font-family;
      }
    }
  }
  &__link {
    display: block;
    padding: 12px 6px;
    border-radius: 4px;
    &.current {
      color: $primary;
    }
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: $bg-hovered;
      }
    }
  }
}

.sidebar-second-list {
  display: none;
  width: 100%;
  border-left: 1px solid $border-color;
  padding-left: 16px;
}
