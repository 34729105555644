@charset 'UTF-8';

@use './global/' as *;

// 変数の設定
:root {
  --grid-gutter: #{$grid-gutter-xs};
  --form-grid-gutter: #{$form-grid-gutter-xs};
  @include media(sm) {
    --grid-gutter: #{$grid-gutter-sm};
    --form-grid-gutter: #{$form-grid-gutter-sm};
  }
  @include media(md) {
    --grid-gutter: #{$grid-gutter-md};
    --form-grid-gutter: #{$form-grid-gutter-md};
  }
  @include media(lg) {
    --grid-gutter: #{$grid-gutter-lg};
    --form-grid-gutter: #{$form-grid-gutter-lg};
  }
  @include media(xl) {
    --grid-gutter: #{$grid-gutter-xl};
    --form-grid-gutter: #{$form-grid-gutter-xl};
  }
}

// grid
//
.grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc(var(--grid-gutter) / -2);
  margin-right: calc(var(--grid-gutter) / -2);
  margin-left: calc(var(--grid-gutter) / -2);
  margin-bottom: calc(var(--grid-gutter) / -2);
  &--center {
    justify-content: center;
  }
  // 隣接
  & + & {
    margin-top: calc(var(--grid-gutter) / 2);
  }
  // col
  .col {
    padding: calc(var(--grid-gutter) / 2);
  }
  &--ac {
    align-items: center;
  }
  &--jc {
    justify-content: center;
  }
}

// Form grid
//
.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc(var(--form-grid-gutter) / -2);
  margin-right: calc(var(--form-grid-gutter) / -2);
  margin-left: calc(var(--form-grid-gutter) / -2);
  margin-bottom: calc(var(--form-grid-gutter) / -2);
  // 隣接
  & + & {
    margin-top: calc(var(--form-grid-gutter) / 2);
  }
  // col
  //
  .col {
    padding: calc(var(--form-grid-gutter) / 2);
  }
}

// item
//
@for $i from 1 through $grid-columns {
  @include make-grid-items(xs, $i);
}

@include media(sm) {
  @for $i from 1 through $grid-columns {
    @include make-grid-items(sm, $i);
  }
}

@include media(md) {
  @for $i from 1 through $grid-columns {
    @include make-grid-items(md, $i);
  }
}

@include media(lg) {
  @for $i from 1 through $grid-columns {
    @include make-grid-items(lg, $i);
  }
}

@include media(xl) {
  @for $i from 1 through $grid-columns {
    @include make-grid-items(xl, $i);
  }
}

// 揃える
//
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
// 縦組み
.justify-end-vertical {
  align-content: flex-end;
}

.justify-center-vertical {
  align-content: center;
}

.justify-between-vertical {
  align-content: space-between;
}

.justify-around-vertical {
  align-content: space-around;
}

// 子要素の天地の揃え
// align-items は親要素 align-self は子要素
//
.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}
