@charset 'UTF-8';

@use '../global/' as *;

.label {
  background-color: $base;
  border: $label-border-width solid transparent;
  border-radius: $label-border-radius;
  color: $white;
  display: inline-block;
  font-size: $label-font-size;
  line-height: 1 !important;
  padding: map-get($label-padding-y, xs) map-get($label-padding-x, xs);
  text-align: center;
  position: relative;

  @each $key, $value in $breakpoints {
    @include media($key) {
      padding: map-get($label-padding-y, $key) map-get($label-padding-x, $key);
    }
  }

  // bacground color pattern
  @each $name, $value in $label-background-colors {
    &.#{$name} {
      background-color: $value;
      color: map-get($label-colors, $name);
    }
  }
  // Color Pattern gray & Basic color
  @each $name, $value in $basic-colors {
    &.#{$name} {
      background-color: $value;
      @if ($name == gray-400 or $name == gray-300 or $name == gray-200 or $name == gray-98 or $name == 'white' or $name == 'yellow') {
        color: inherit;
      } @else {
        color: $white;
      }
    }
  }

  &.outline {
    background-color: $white;
    border: $label-border-width solid $base;
    color: $base;

    // border color pattern
    $label-all-colors: map-merge($label-background-colors, $basic-colors);
    @each $name, $value in $label-all-colors {
      &.#{$name} {
        border-color: $value;
        color: $value;
      }
    }
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-left: 0.5em;
  margin-right: 0;
  &.left {
    margin-left: 0;
    margin-right: 0.5em;
  }
}
