@charset 'UTF-8';

@use '../global/' as *;

// Construct layout
.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  min-height: 100vh;
  &#{$burger-open-class} {
    overflow: hidden;
    height: 100vh;
    .construct-header {
      position: fixed;
    }
  }
  @include media(lg) {
    padding-top: 0;
  }
}

.construct-header {
  background-color: transparent;
  flex: none;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $z-index-header;
  &.upMove {
    @include media(lg) {
      animation: UpAnime 0.5s forwards;
    }
  }

  @keyframes UpAnime {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-100px);
    }
  }

  &.downMove {
    @include media(lg) {
      animation: DownAnime 0.5s forwards;
    }
  }
  @keyframes DownAnime {
    from {
      opacity: 0;
      transform: translateY(-100px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.construct-nav {
  width: 342px;
  height: 100vh;
  position: fixed;
  top: 57px;
  right: 0;
  transform: translateX(100%);
  transition: transform $base-transition;
  z-index: $z-index-nav;
  // OPEN
  #{$burger-open-class} & {
    transform: translateX(0);
  }
  @include media(lg) {
    width: 382px;
    top: 99px;
  }
}

.construct-overlay {
  position: fixed;
  width: 100%;
  top: 45px;
  left: 0;
  opacity: 0;
  z-index: -4;
  transition: opacity $base-transition;
  #{$burger-open-class} & {
    height: 100vh;
    opacity: 1;
    background-color: rgba($color: $base, $alpha: 0.45);
  }
  @include media(lg) {
    top: 99px;
  }
}

.construct-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
}

.construct-footer {
  position: relative;
}

// Container
.container {
  padding: 32px 0 48px;
  @include media(lg) {
    width: 100%;
    margin: 0 auto;
    padding: 62px 40px 96px;
  }
}

// Contents
.contents {
  .content-inner {
    padding: 0 20px;
    @include media(lg) {
      padding: 0;
    }
  }
}
// 1カラム
.one-col-container {
  .contents {
    .content-inner {
      width: 100%;
      max-width: 1040px;
      margin: 0 auto;
    }
  }
}
// 2カラム
.two-col-container {
  .container {
    padding-bottom: 0;
    @include media(lg) {
      padding-bottom: 77px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 80px;
    }
  }
  .contents-wrapper {
    flex: 1;
  }
  .contents {
    width: 100%;
    @include media(lg) {
      max-width: 1040px;
      margin: 0 auto;
      position: relative;
    }
  }
  .sidebar {
    @include media(lg) {
      padding-right: 40px;
      border-right: 1px solid $border-color;
    }
  }
  .breadlist-wrapper {
    background-color: $bg-default;
  }
}

// section
.section {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key, true) {
        margin-top: map-get($section-block-gutters, $key);
      }
    }
  }
}
