@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.caution-news-list {
  flex: 1;
  @include sys.media(lg) {
    width: 70%;
    margin-top: 8px;
  }
}

.caution-news-container {
  transition: color g.$base-transition;
  display: flex;
  flex-direction: column;
  @include sys.media(lg) {
    padding: 0.1em 0;
    flex-direction: row;
    align-items: center;
  }
  & + & {
    margin-top: 22px;
    @include sys.media(lg) {
      margin-top: 13px;
    }
  }

  .news-body {
    width: 100%;
  }

  .news-anchor {
    display: block;
  }

  .date {
    color: rgba($color: g.$important, $alpha: 0.5);
    font-size: 1.4rem;
    line-height: 1.71;
    @include sys.media(lg) {
      margin-right: 24px;
    }
  }

  .category {
    @include sys.media(lg) {
      padding: 24px 10px;
    }

    .category-label {
      background-color: g.$gray-container;
      border-radius: 2em;
      color: g.$base;
      display: block;
      font-size: 1rem;
      padding: 8px 16px;
      text-align: center;
      white-space: nowrap;
      width: 100%;
      @include sys.media(lg) {
        min-width: 7em;
      }
    }
  }
  .title {
    margin-top: 2px;
    overflow: hidden;
    font-size: 1.4rem;
    @include sys.media(lg) {
      width: calc(100% - 112px);
      font-size: 1.6rem;
      margin-top: 0;
    }
    a {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: g.$important;
    }
  }
}
