@charset 'UTF-8';

@use './color' as *;
@use 'sass:math';

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1360px,
) !default;

// Container
$container-max-width: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 992px,
  xl: 1200px,
) !default;

// Base text color
$base-text-color: $base !default;

// Base background color
$base-background-color: $white !default;

// Base font size
$base-font-size: 1.6rem !default;

// root font size
$root-font-size: (
  xs: 1.4rem,
  sm: 1.4rem,
  md: 1.6rem,
  lg: 1.6rem,
  xl: 1.6rem,
) !default;

$font-size-percentage: (
  'xsmall': 62.5%,
  'small': 75%,
  'medium': 87.5%,
  'large': 112.5%,
  'xlarge': 125%,
  'xxlarge': 150%,
  'exlarge': 200%,
) !default;

$base-line-height: 1.75 !default;

$base-letter-spacing: 0.03em;

// TODO: Refactoring
// Font size set
$font-sets: (
  46: 150%,
  41: 150%,
  36: 160%,
  32: 160%,
  29: 160%,
  26: 170%,
  23: 170%,
  20: 170%,
  18: 175%,
  16: 175%,
  14: 175%,
  13: 175%,
  11: 175%,
) !default;

// TODO: Refactoring
// Spacing
$spacing-sets: (
  'xxxxl': 64px,
  'xxxl': 48px,
  'xxl': 40px,
  'xl': 32px,
  'lg': 24px,
  'md': 16px,
  'sm': 12px,
  'xs': 8px,
  'xxs': 4px,
  'xxxs': 2px,
) !default;

// TODO: Refactoring
// Border radius
$border-radius-sets: (
  'infinity': 999px,
  'xl': 16px,
  'lg': 12px,
  'md': 8px,
  'sm': 4px,
  'xs': 2px,
) !default;

// Heading
$h1-font-size: 2.6rem !default;
$h2-font-size: 2.3rem !default;
$h3-font-size: 2rem !default;
$h4-font-size: 1.8rem !default;
$h5-font-size: 1.7rem !default;
$h6-font-size: 1.6rem !default;

$h1-margin-top: 32px !default;
$h2-margin-top: 32px !default;
$h3-margin-top: 24px !default;
$h4-margin-top: 16px !default;
$h5-margin-top: 15px !default;
$h6-margin-top: 15px !default;

$h1-margin-bottom: 32px !default;
$h2-margin-bottom: 24px !default;
$h3-margin-bottom: 16px !default;
$h4-margin-bottom: 15px !default;
$h5-margin-bottom: 15px !default;
$h6-margin-bottom: 15px !default;

$heading-line-height: $base-line-height !default;

$heading-letter-spacing: $base-letter-spacing !default;

// Paragraph
// .lead
$lead-letter-spacing: 0.03em !default;
$lead-line-height: 1.75 !default;
$lead-top-spacer: 1em !default;

// Spacer
$base-spacer: 10px !default;

// Base gutter
$base-gutter: 20px !default;

$base-gutter-xs: $base-gutter !default;
$base-gutter-sm: $base-gutter !default;
$base-gutter-md: $base-gutter * 1.333333 !default;
$base-gutter-lg: $base-gutter * 2 !default;
$base-gutter-xl: $base-gutter * 3 !default;

$base-gutters: (
  xs: $base-gutter-xs,
  sm: $base-gutter-sm,
  md: $base-gutter-md,
  lg: $base-gutter-lg,
  xl: $base-gutter-xl,
) !default;

// Base side padding
$base-side-padding-xs: $base-gutter-xs !default;
$base-side-padding-sm: $base-gutter-sm !default;
$base-side-padding-md: $base-gutter-md !default;
$base-side-padding-lg: $base-gutter-lg !default;
$base-side-padding-xl: $base-gutter-xl !default;

$base-side-padding: (
  xs: $base-side-padding-xs,
  sm: $base-side-padding-sm,
  md: $base-side-padding-md,
  lg: $base-side-padding-lg,
  xl: $base-side-padding-xl,
) !default;

// header height
$header-height-xs: 56px !default;
$header-height-sm: 56px !default;
$header-height-md: 56px !default;
$header-height-lg: 98px !default;
$header-height-xl: 98px !default;

$header-height: (
  xs: $header-height-xs,
  sm: $header-height-sm,
  md: $header-height-md,
  lg: $header-height-lg,
  xl: $header-height-xl,
) !default;

// Section block
$section-block-gutter-xs: 38px !default;
$section-block-gutter-sm: 38px !default;
$section-block-gutter-md: 38px !default;
$section-block-gutter-lg: 63px !default;
$section-block-gutter-xl: 63px !default;

$section-block-gutters: (
  xs: $section-block-gutter-xs,
  sm: $section-block-gutter-sm,
  md: $section-block-gutter-md,
  lg: $section-block-gutter-lg,
  xl: $section-block-gutter-xl,
) !default;

// Grid
$grid-columns: 12 !default;

$grid-gutter-xs: 8px !default;
$grid-gutter-sm: 8px !default;
$grid-gutter-md: 8px !default;
$grid-gutter-lg: 16px !default;
$grid-gutter-xl: 16px !default;

$grid-gutters: (
  xs: $grid-gutter-xs,
  sm: $grid-gutter-sm,
  md: $grid-gutter-md,
  lg: $grid-gutter-lg,
  xl: $grid-gutter-xl,
) !default;

// Anchor links
$base-link-color: $link-color !default;
$base-link-decoration: none !default;
$base-link-hover-color: inherit !default;
$base-link-hover-decoration: none !default;

// Horizontal line
$hr-border: 1px solid $gray-60 !default;
$hr-y-gutter: 10px !default;

// Label
$label-font-size: 0.75em;

$label-padding-y: (
  xs: 0.425em,
  sm: 0.425em,
  md: 0.5em,
  lg: 0.4em,
  xl: 0.4em,
) !default;

$label-padding-x: (
  xs: 0.6em,
  sm: 0.6em,
  md: 0.6em,
  lg: 0.6em,
  xl: 0.6em,
) !default;

// 角丸
$label-border-radius: 0px !default;

// アウトライン時のボーダーの太さ
$label-border-width: 1px !default;

// background color
$label-background-colors: (
  'base': $base,
  'primary': $primary,
  'secondary': $secondary,
) !default;

// text color
$label-colors: (
  'base': $white,
  'primary': $white,
  'secondary': $white,
) !default;

// Table
$table-border-color: $border-color !default;
$table-th-background-color: $bg-default !default;
$table-td-background-color: $white !default;
$table-thead-th-background: $bg-default !default;
$table-tbody-th-background: $gray-container !default;

// Base transition
$base-transition: 0.2s ease-out;

// Base box-shadw
$base-box-shadow: 0 5px 10px rgba($black, 0.15) !default;
$base-box-shadow-sm: 0 1.25px 2.5px rgba($black, 0.075) !default;
$base-box-shadow-lg: 0 10px 30px rgba($black, 0.175) !default;

// breakpoint ごとの比率
$scale-sm: math.div(map-get($root-font-size, sm), map-get($root-font-size, xs));
$scale-md: math.div(map-get($root-font-size, md), map-get($root-font-size, xs));
$scale-lg: math.div(map-get($root-font-size, lg), map-get($root-font-size, xs));
$scale-xl: math.div(map-get($root-font-size, xl), map-get($root-font-size, xs));

$scales: (
  sm: $scale-sm,
  md: $scale-md,
  lg: $scale-lg,
  xl: $scale-xl,
) !default;

// z-index
$z-index-header: 5 !default;
$z-index-nav: 3 !default;
$z-index-main: 0 !default;
$z-index-footer: 1 !default;
$z-index-pagetop: 4 !default;
$z-index-loading: 999 !default;
