@charset 'UTF-8';

@use '../global/' as *;

.btn {
  width: 100%;
  min-width: 180px;
  max-width: 238px;
  background-color: $secondary;
  border: $btn-border-width solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  @include fontsize(14);
  font-weight: normal;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: opacity $base-transition, color $base-transition, background-color $base-transition;
  vertical-align: middle;
  white-space: nowrap;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  line-height: normal;
  border-radius: 30px;
  @include media(lg) {
    font-size: $btn-font-size-normal;
  }

  // Sizes
  @each $name, $value in $btn-font-sizes {
    &.#{$name} {
      font-size: $value;
      padding: map-get($btn-y-paddings, $name) map-get($btn-x-paddings, $name);
    }
  }

  // hover
  @media (hover: hover) {
    &:hover {
      opacity: 1;
      background-color: $bg-hovered;
      color: $primary;
    }
  }

  // Colors variation
  @each $name, $value in $btn-background-colors {
    &.#{$name} {
      background-color: $value;
      color: map-get($btn-colors, $name);
      @media (hover: hover) {
        &:hover {
          background-color: map-get($btn-hover-background-colors, $name);
          color: map-get($btn-hover-colors, $name);
        }
      }
    }
  }

  // Base
  &.base {
    min-width: 100%;
    max-width: none;
    padding: $btn-y-padding-large $btn-x-padding-large;
    @include media(md) {
      min-width: 218px;
    }
  }

  // Outline
  &.outline {
    background-color: $white;
    border: $btn-border-width solid $base;
    color: $base;
    transition: opacity $base-transition, background-color $base-transition;
    // Outline hover
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: $bg-hovered;
      }
    }
    // Outline colors
    @each $name, $value in $btn-background-colors {
      &.#{$name} {
        border: $btn-border-width solid $value;
        color: map-get($all-colors, $name);
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &-square {
    width: 100%;
    height: 100%;
    padding: 16px 40px 16px 20px;
    border: 1px solid $border-color;
    border-radius: 8px;
    display: inline-block;
    position: relative;
    line-height: 1.4;
    background-color: $white;
    display: flex;
    align-items: center;
    &::first-line {
      line-height: 1;
    }
    &::after {
      width: 1em;
      position: absolute;
      top: calc(50% - 0.6em);
      right: 1em;
      font-family: $icon-font-family;
      content: '';
    }
    &--self,
    &--primary {
      &::after {
        content: $arrow-right;
      }
    }
    &--blank {
      &::after {
        content: $open-new-window;
      }
    }
    &--pdf {
      &::after {
        content: $pdf;
      }
    }
    &--plus {
      &::after {
        content: $plus;
      }
    }
    &--primary {
      border-color: $primary;
    }
    &--anker {
      &::after {
        content: $chevron-down;
      }
    }
    @media (hover: hover) {
      &:hover {
        background-color: $bg-hovered;
      }
    }
  }
}
