@charset 'UTF-8';

@use '../global/' as *;

ul.list,
ol.list {
  li:not(:last-child) {
    margin-bottom: 16px;
  }
  ul,
  ol {
    margin-left: 1em;
  }

  // インデント
  &.indent {
    margin-left: 0.5em;
    ul,
    ol {
      margin-top: 0.8em;
    }
  }
}

ul.list,
ul.list-row {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
    ul {
      list-style: circle outside;
    }
  }
  // 四角 & 丸
  &.dot,
  &.square,
  &.circle,
  &.circle-white,
  &.kome,
  &.checkbox,
  &.circle-double {
    & > li {
      line-height: inherit;
      padding-left: 1.4em;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.dot {
    & > li {
      &::before {
        width: 5px;
        height: 5px;
        background: $primary;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 12px;
        content: '';
      }
    }
  }

  &.square {
    & > li {
      &:before {
        content: '■';
      }
    }
  }

  &.circle {
    & > li {
      &:before {
        content: '●';
      }
    }
  }
  &.circle-white {
    & > li {
      &:before {
        content: '○';
      }
    }
  }
  &.kome {
    & > li {
      &:before {
        content: '※';
      }
    }
  }
  &.checkbox {
    & > li {
      &:before {
        content: $checkbox-checked;
        font-family: $icon-font-family;
      }
    }
  }
  &.circle-double {
    & > li {
      &:before {
        content: '';
        width: 1em;
        height: 1em;
        margin-top: 0.5em;
        border: 4px solid $primary;
        border-radius: 50%;
      }
    }
  }
}

ol.list,
ol.list-row {
  // 数字
  &.number {
    list-style: decimal outside;
    padding-left: 1.125em;
    ol {
      list-style: decimal outside;
    }
  }
}

dl.list {
  dt {
    font-weight: bold;
    &:nth-of-type(n + 2) {
      margin-top: 1em;
    }
  }
  dd {
    line-height: 1.4;
  }
}

// list の横並び
//
.list-row {
  display: inline-flex;
  flex-flow: column nowrap;
  gap: 10px;
  @include media(md) {
    flex-flow: row wrap;
  }
  li {
    &:not(:last-child) {
      @include media(md) {
        margin-right: 20px;
      }
    }
    & + li {
      margin-top: 20px;
      @include media(md) {
        margin-top: 0;
      }
    }
  }
  &--center {
    width: 100%;
    align-items: center;
  }
  &--nowrap {
    display: flex;
    flex-flow: row nowrap;
    li {
      & + li {
        margin-top: 0;
      }
    }
  }
}

//定義リスト
dl.dl-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  dt {
    color: $primary;
    font-weight: bold;
    display: block;
    padding-bottom: 12px;
    &:nth-of-type(n + 2) {
      margin-top: 25px;
    }
    @include media(md) {
      border-bottom: 1px solid $border-color;
      padding-bottom: 24px;
      width: 25%;
    }
    @include media(xl) {
      width: 20%;
    }
  }
  dd {
    border-bottom: 1px solid $border-color;
    padding-bottom: 24px;
    width: 100%;
    @include media(md) {
      width: 75%;
      padding-left: 2%;
      padding-bottom: 24px;
      &:nth-of-type(n + 2) {
        margin-top: 25px;
      }
    }
    @include media(xl) {
      width: 80%;
    }
  }

  //定義リストdt 1-20em
  @for $i from 1 through 20 {
    &.dl-list-#{$i} {
      dt {
        @include media(md) {
          width: #{$i}em;
        }
      }
      dd {
        @include media(md) {
          width: calc(100% - #{$i}em);
          padding-left: 2%;
          padding-bottom: 25px;
        }
      }
    }
  }
}

// フロー
.flow {
  &__title {
    margin-bottom: 0;
    padding: 8px 0 10px 52px;
    color: $base;
    font-weight: bold;
    @include fontsize(18);
    @include media(lg) {
      padding: 7px 0 10px 64px;
    }
  }
  &__text {
    margin: 0 0 0 52px;
    @include media(lg) {
      margin: 0 0 0 64px;
    }
  }
  & > li {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    background-color: $bg-default;
    @include media(lg) {
      padding: 24px 24px 32px;
    }
    & + li {
      margin-top: 16px;
      position: relative;
      @include media(lg) {
        margin-top: 24px;
      }
      &::before {
        content: '';
        width: 1px;
        height: 16px;
        position: absolute;
        top: -16px;
        left: 50%;
        background-color: $secondary;
        transform: translateX(-50%);
        @include media(lg) {
          height: 24px;
          top: -24px;
        }
      }
    }
    &:nth-of-type(n) {
      .flow__title {
        &::before {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          color: $primary;
          font-weight: normal;
          font-size: 2rem;
          font-family: $base-font-family-en;
          line-height: 36px;
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 23px;
          left: 23px;
          @include media(lg) {
            width: 44px;
            height: 44px;
            line-height: 44px;
            top: 25px;
            left: 25px;
          }
        }
      }
    }
    &:nth-of-type(1) {
      .flow__title {
        &::before {
          content: '1';
        }
      }
    }
    &:nth-of-type(2) {
      .flow__title {
        &::before {
          content: '2';
        }
      }
    }
    &:nth-of-type(3) {
      .flow__title {
        &::before {
          content: '3';
        }
      }
    }
    &:nth-of-type(4) {
      .flow__title {
        &::before {
          content: '4';
        }
      }
    }
    &:nth-of-type(5) {
      .flow__title {
        &::before {
          content: '5';
        }
      }
    }
    &:nth-of-type(6) {
      .flow__title {
        &::before {
          content: '6';
        }
      }
    }
    &:nth-of-type(7) {
      .flow__title {
        &::before {
          content: '7';
        }
      }
    }
    &:nth-of-type(8) {
      .flow__title {
        &::before {
          content: '8';
        }
      }
    }
    &:nth-of-type(9) {
      .flow__title {
        &::before {
          content: '9';
        }
      }
    }
    &:nth-of-type(10) {
      .flow__title {
        &::before {
          content: '10';
        }
      }
    }
  }
}
