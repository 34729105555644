@charset 'UTF-8';

@use '../global/' as *;

// variable
$pagination-active-class: '.current';

.pagination-wrapper {
  width: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  gap: 16px;

  li > * {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    line-height: 1;
  }

  .pager {
    background-color: $white;
    transition: background $base-transition;
    @media (hover: hover) {
      &:hover {
        background: $bg-default;
      }
    }
  }
  #{$pagination-active-class} {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  .prev,
  .next {
    font-size: 0;
    &::before {
      font-size: 1.2rem;
    }
  }

  .prev {
    &::before {
      content: '<';
    }
  }
  .next {
    &::before {
      content: '>';
    }
  }
}
