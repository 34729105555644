@charset 'UTF-8';

@use '../global/' as *;

// variable
//
$pagetop-active-class-name: '.pagetop-active';

.pagetop {
  width: 32px;
  height: 32px;
  border: 1px solid $border-color;
  border-radius: 50%;
  background-color: $white;
  text-align: center;
  position: absolute;
  right: 20px;
  bottom: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity $base-transition;
  cursor: pointer;
  z-index: $z-index-pagetop;
  &::before {
    content: $chevron-up;
    font-family: $icon-font-family;
    color: $primary;
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media(md) {
    right: 56px;
    bottom: 60px;
  }

  &#{$pagetop-active-class-name} {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
