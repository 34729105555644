@charset 'UTF-8';

@use './global/' as *;

// navigation
.navigation-list {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.navigation-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .icon {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;
    &::after {
      margin: 0;
    }
  }
  @media (hover: hover) {
    &.hover {
      .navigation-link {
        opacity: 0.5;
      }
    }
  }
}

.navigation-link,
.navigation-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  transition: opacity $base-transition;
  position: relative;
  border-bottom: 1px solid $border-color;
  flex: 1;

  &::first-line {
    line-height: 1;
  }

  &::after {
    content: '';
    width: 1px;
    height: 28px;
    margin: 0 8px;
    display: block;
    background-color: $border-color;
  }
}

// 子メニュー
//
.navigation-child {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height $base-transition;
}

.navigation-child-list {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid $border-color;
  .navigation-child-link {
    display: block;
    position: relative;
  }
}
