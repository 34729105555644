@charset 'UTF-8';

@use '../global/' as *;

// breadlist-wrapper
//
.breadlist-wrapper {
  padding: 17px 0;
  overflow: auto;
  @include media(lg) {
    padding: 21px 0;
  }
}

// breadlist-container
//
.breadlist-container {
  padding: 0 16px;
  @include media(lg) {
    padding: 0 32px;
  }
}

// breadlist
//
ol.breadlist {
  display: flex;
  align-items: baseline;
  @include fontsize(13);
  @include media(lg) {
    @include fontsize(14);
  }

  li {
    color: inherit;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: $c-pankuzu-arrow;
      padding-right: 8px;
      padding-left: 8px;
      box-sizing: content-box;
      font-family: $icon-font-family;
      color: $subtle;
      font-size: 0.8rem;
      vertical-align: middle;
      @include media(lg) {
        padding-right: 12px;
        padding-left: 12px;
      }
    }

    a {
      color: $subtle;
      &::active,
      &::visited {
        color: inherit;
      }
    }

    &.current {
      color: $base;
    }
  }
}
