@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.btn-anchor {
  background-color: g.$white;
  border: 1px solid g.$secondary;
  border-radius: 6px;
  color: g.$secondary;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8em;
  padding: 0.8em 1em;

  &::after {
    content: g.$caret-down;
    font-family: g.$icon-font-family;
    color: g.$secondary;
    display: inline-block;
    transform: translateY(0);
    transition: transform g.$base-transition;
    width: 1em;
    height: 1em;
  }
  @media (hover: hover) {
    &:hover {
      //background-color: g.$tertiary;
      //color: g.$secondary;
      &::after {
        transform: translateY(5px);
      }
    }
  }

  //@include sys.media(md) {
  //  margin-bottom: -.2em;
  //  padding: 1em 1.5em 1.8em;
  //  width: 100%;
  //}
  //@include sys.media(lg) {
  //  margin-bottom: -1em;
  //}
}

.btn-disabled {
  pointer-events: none;
}
