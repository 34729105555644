@charset 'UTF-8';

/*-------------------------
  theme colors
-------------------------*/
$base: #333333 !default;
$body-text: $base;
$body-text-60: rgba(
  $color: $base,
  $alpha: 0.6,
);

$primary: #1e72c8 !default;
$secondary: #fb893d !default;
$error: #ec1e14 !default;
$important: $error;
$subtle: #737373 !default;
$placeholder: #b3b3b3 !default;

$bg-default: #f8f9fb !default;
$bg-variant: #eff2f5 !default;
$bg-error: #fef6f6 !default;
$bg-important: $bg-error !default;
$bg-highlight: #fff9f5 !default;
$bg-sidebar: #f6fafe !default;
$bg-hovered: #e9f2fc !default;

$border-color: #dee4eb !default;
$border-subtle: #f8f9fb !default;
$border-strong: #4a627e !default;
$border-highlight: #fb893d !default;

$link-color: #0033cc !default;
$maker-color: #fffdc3 !default;

$overlay-color: rgba(0, 0, 0, 20%) !default;
$overlay-on: rgba(0, 0, 0, 8%) !default;

$gradation: linear-gradient(180deg, $secondary, $primary);

/* primary
-------------------------*/
$primary-10: #07192c !default;
$primary-20: #0d3259 !default;
$primary-30: #144c85 !default;
$primary-40: #1a65b2 !default;
$primary-50: $primary !default;
$primary-60: #4d98e5 !default;
$primary-70: #7ab2eb !default;
$primary-80: #a6cbf2 !default;
$primary-90: #d3e5f8 !default;
$primary-95: #e9f2fc !default;
$primary-98: #f6fafe !default;
$primary-container: $primary-98 !default;
$primary-on-high: rgba(
  $color: $primary,
  $alpha: 1,
) !default;
$primary-on-medium: rgba(
  $color: $primary,
  $alpha: 0.74,
) !default;
$primary-on-disabled: rgba(
  $color: $primary,
  $alpha: 0.38,
) !default;

/* secondary
-------------------------*/
$secondary-10: #321603 !default;
$secondary-20: #642b05 !default;
$secondary-30: #964108 !default;
$secondary-40: #c8560a !default;
$secondary-50: #fa6c0d !default;
$secondary-60: $secondary !default;
$secondary-70: #fca76e !default;
$secondary-80: #fdc49e !default;
$secondary-90: #fee2cf !default;
$secondary-95: #fef0e7 !default;
$secondary-98: #fff9f5 !default;
$secondary-container: $secondary-98 !default;
$secondary-on-high: rgba(
  $color: $secondary,
  $alpha: 1,
) !default;
$secondary-on-medium: rgba(
  $color: $secondary,
  $alpha: 0.74,
) !default;
$secondary-on-disabled: rgba(
  $color: $secondary,
  $alpha: 0.38,
) !default;

/* natural
-------------------------*/
$natural-10: #14191d !default;
$natural-20: #29313b !default;
$natural-30: #37495e !default;
$natural-40: #4a627e !default;
$natural-50: #5c7a9d !default;
$natural-60: #7d95b1 !default;
$natural-70: #9dafc4 !default;
$natural-80: #becad8 !default;
$natural-90: #dee4eb !default;
$natural-95: #eff2f5 !default;
$natural-98: #f8f9fb !default;
$natural-container: $natural-98 !default;
$natural-on-high: rgba(
  $color: $natural-50,
  $alpha: 1,
) !default;
$natural-on-medium: rgba(
  $color: $natural-50,
  $alpha: 0.74,
) !default;
$natural-on-disabled: rgba(
  $color: $natural-50,
  $alpha: 0.38,
) !default;

/* error
-------------------------*/
$error-10: #2f0604 !default;
$error-20: #5e0c08 !default;
$error-30: #8e120c !default;
$error-40: #bd1810 !default;
$error-50: $error !default;
$error-60: #f04b43 !default;
$error-70: #f47872 !default;
$error-80: #f7a5a1 !default;
$error-90: #fbd2d0 !default;
$error-95: #fde8e7 !default;
$error-98: #fef6f6 !default;
$error-container: $error-98 !default;
$error-on-high: rgba(
  $color: $error,
  $alpha: 1,
) !default;
$error-on-medium: rgba(
  $color: $error,
  $alpha: 0.74,
) !default;
$error-on-disabled: rgba(
  $color: $error,
  $alpha: 0.38,
) !default;

$theme-colors: (
  'base': $base,
  'primary': $primary,
  'secondary': $secondary,
  'error': $error,
  'important': $important,
  'subtle': $subtle,
  'link-color': $link-color,
  'bg-link': $bg-default,
  'bg-variant': $bg-variant,
  'bg-error': $bg-error,
  'bg-important': $bg-important,
  'bg-highlight': $bg-highlight,
  'bg-sidebar': $bg-sidebar,
  'bg-hovered': $bg-hovered,
  'border-color': $border-color,
  'border-subtle': $border-subtle,
  'border-strong': $border-strong,
  'border-highlight': $border-highlight,
  'maker-color': $maker-color,
  'overlay-color': $overlay-color,
  'overlay-on': $overlay-on,
  'primary-container': $primary-container,
  'secondary-container': $secondary-container,
  'error-container': $error-container,
  'primary-10': $primary-10,
  'primary-20': $primary-20,
  'primary-30': $primary-30,
  'primary-40': $primary-40,
  'primary-50': $primary-50,
  'primary-60': $primary-60,
  'primary-70': $primary-70,
  'primary-80': $primary-80,
  'primary-90': $primary-90,
  'primary-95': $primary-95,
  'primary-98': $primary-98,
  'primary-on-high': $primary-on-high,
  'primary-on-medium': $primary-on-medium,
  'primary-on-disabled': $primary-on-disabled,
  'secondary-10': $secondary-10,
  'secondary-20': $secondary-20,
  'secondary-30': $secondary-30,
  'secondary-40': $secondary-40,
  'secondary-50': $secondary-50,
  'secondary-60': $secondary-60,
  'secondary-70': $secondary-70,
  'secondary-80': $secondary-80,
  'secondary-90': $secondary-90,
  'secondary-95': $secondary-95,
  'secondary-98': $secondary-98,
  'secondary-on-high': $secondary-on-high,
  'secondary-on-medium': $secondary-on-medium,
  'secondary-on-disabled': $secondary-on-disabled,
  'natural-10': $natural-10,
  'natural-20': $natural-20,
  'natural-30': $natural-30,
  'natural-40': $natural-40,
  'natural-50': $natural-50,
  'natural-60': $natural-60,
  'natural-70': $natural-70,
  'natural-80': $natural-80,
  'natural-90': $natural-90,
  'natural-95': $natural-95,
  'natural-98': $natural-98,
  'natural-on-high': $natural-on-high,
  'natural-on-medium': $natural-on-medium,
  'natural-on-disabled': $natural-on-disabled,
  'error-10': $error-10,
  'error-20': $error-20,
  'error-30': $error-30,
  'error-40': $error-40,
  'error-50': $error-50,
  'error-60': $error-60,
  'error-70': $error-70,
  'error-80': $error-80,
  'error-90': $error-90,
  'error-95': $error-95,
  'error-98': $error-98,
  'error-on-high': $error-on-high,
  'error-on-medium': $error-on-medium,
  'error-on-disabled': $error-on-disabled,
) !default;

/*-------------------------
  common color
-------------------------*/
$red: #da4453 !default;
$orange: #e9573f !default;
$yellow: #fff350 !default;
$green: #8cc152 !default;
$blue: $primary !default;
$pink: #f16f77 !default;
$purple: #967adc !default;
$cyan: #4fc1e9 !default;
$gray: #bdbdbd !default;
$black: #000 !default;
$white: #fff !default;

$light-gray: lighten($gray, 25%);
$light-green: lighten($green, 42%);
$light-pink: lighten($pink, 33%);

$commons-colors: (
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'blue': $blue,
  'pink': $pink,
  'purple': $purple,
  'cyan': $cyan,
  'gray': $gray,
  'black': $black,
  'white': $white,
  'light-gray': $light-gray,
  'light-green': $light-green,
  'light-pink': $light-pink,
) !default;

$gray-10: #1a1a1a !default;
$gray-20: #333333 !default;
$gray-30: #4d4d4d !default;
$gray-40: #666666 !default;
$gray-50: #808080 !default;
$gray-60: #999999 !default;
$gray-70: #b3b3b3 !default;
$gray-80: #cccccc !default;
$gray-90: #e6e6e6 !default;
$gray-95: #f2f2f2 !default;
$gray-98: #fafafa !default;
$gray-container: $gray-98 !default;
$gray-dark: rgba(51, 51, 51, 0.7) !default;
$gray-sub: #6e6e6e !default;

$grays: (
  'gray-10': $gray-10,
  'gray-20': $gray-20,
  'gray-30': $gray-30,
  'gray-40': $gray-40,
  'gray-50': $gray-50,
  'gray-60': $gray-60,
  'gray-70': $gray-70,
  'gray-80': $gray-80,
  'gray-90': $gray-90,
  'gray-95': $gray-95,
  'gray-98': $gray-98,
  'gray-container': $gray-container,
  'gray-dark': $gray-dark,
  'gray-sub': $gray-sub,
) !default;

// カラーの配列を作る
//
// グレースケールと汎用カラーの配列
$basic-colors: map-merge($grays, $commons-colors);

// テーマカラーを含めたすべての配列
$all-colors: map-merge($theme-colors, $basic-colors);
