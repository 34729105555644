@charset 'UTF-8';

@use '../global/' as *;
@use 'sass:math';

// wrapper
.global-nav-wrapper {
  height: calc(100% - 57px);
  padding: 12px 32px 105px;
  overflow: auto;
  background-color: $primary;
  @include media(lg) {
    height: calc(100% - 99px);
    padding: 26px 39px 105px;
  }

  .global-nav-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  // navigation
  .navigation-item {
    .js-trigger {
      cursor: pointer;
      &::after {
        font-size: 2.4rem;
      }
    }
    &.active {
      .js-trigger {
        &::after {
          content: $minus;
        }
      }
    }
    .icon:not(.js-trigger) {
      &::after {
        content: $chevron-right;
        font-size: 2.4rem;
      }
    }
    .icon {
      color: $white;
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    }
  }

  .navigation-link,
  .navigation-category {
    font-weight: normal;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    color: $white;

    &::after {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
  }

  // 子メニュー
  .navigation-child-list {
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    .navigation-child-link {
      color: $white;
    }
  }

  .tel {
    a {
      color: $white;
      font-weight: normal;
      font-size: 24px;
      font-family: $base-font-family-en;
      letter-spacing: 0.01em;
      .icon {
        &::before {
          top: 0.16em;
          margin-right: 0;
        }
      }
    }
  }
  .searchbox {
    border: 1px solid $white;
    border-radius: 4px;
    color: $white;
    input[type='text'] {
      flex: 1;
    }
    input[type='text']::placeholder {
      font-size: 16px;
      color: rgba($color: $white, $alpha: 0.5);
    }
    .search-inner {
      padding: 12px;
    }

    .icon {
      &::before {
        margin: 0 !important;
      }
    }
  }
  .searchbox-inner {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
