@charset 'UTF-8';

@use '../global/' as *;

.header-wrapper {
  border-bottom: 1px solid $border-color;
  background-color: $white;
  position: relative;
  &.upMove {
    @include media(lg) {
      animation: UpAnime 0.5s forwards;
    }
  }

  @keyframes UpAnime {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-100px);
    }
  }

  &.downMove {
    @include media(lg) {
      animation: DownAnime 0.5s forwards;
    }
  }
  @keyframes DownAnime {
    from {
      opacity: 0;
      transform: translateY(-100px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @each $key, $value in $header-height {
    @include media($key, true) {
      height: $value !important;
    }
  }
}

.header-inner-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-logo {
  width: 168px;
  height: 100%;
  margin: 0;
  padding: 7px;
  display: flex;
  align-items: center;
  @include media(md) {
    width: 240px;
  }
  @include media(xl) {
    width: 300px;
    padding: 0 20px;
    border-right: 1px solid $border-color;
  }
}

// fontsize swich
.header-switch-fontsize {
  display: none;
  @include media(lg) {
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $subtle;
    border-right: 1px solid $border-color;
    font-size: 11px;
  }
}

.fontsize-swich-checkbox {
  display: none;
  &:checked {
    & + .fontsize-swich-container {
      &::before {
        left: 50%;
      }
    }
  }
}

.fontsize-swich-container {
  margin-top: 10px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  background: $natural-90;
  border: 2px solid $natural-90;
  @include border-radius(sm);
  font-size: 14px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0%;
    @include border-radius(sm);
    background: $white;
    transition: left 0.3s;
  }
  div {
    padding: 3px 8.5px;
    text-align: center;
    z-index: 1;
  }
}

.header-inner-center {
  display: none;
  @include media(lg) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
  }
}

// Header Navi
.header-nav {
  display: flex;
  gap: 24px;
  @include media(xl) {
    gap: 38px;
  }
  .js-trigger {
    display: flex;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        position: relative;
        top: 0.04em;
        font-size: 2.4rem;
      }
    }
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        color: $primary;
      }
    }
  }
}
.header-nav-child {
  width: 100%;
  max-height: 0;
  position: fixed;
  top: 99px;
  left: 0;
  background-color: $bg-hovered;
  transition: max-height $base-transition;
  overflow: hidden;
}
.header-nav-child-inner {
  max-width: 1024px;
  max-height: 85vh;
  margin: 48px auto 64px;
  box-sizing: content-box;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  overflow: auto;
  & > * {
    width: calc((100% - 80px) / 3);
  }
}
.header-nav-childbox {
  padding: 0 0 0 40px;
  &:not(:first-child) {
    border-left: 1px solid $border-color;
  }
  &--full {
    width: 100%;
    .header-nav-child-list {
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: calc((100% - 56px) / 3);
        padding-bottom: 14px;
        border-bottom: 1px solid $border-color;
      }
    }
  }
}
.header-nav-child-category {
  margin-bottom: 16px;
  a {
    font-weight: bold;
    color: $subtle;
  }
}
.header-nav-child-list {
  display: flex;
  flex-direction: column;
  gap: 12px 28px;
  .icon.link {
    align-items: baseline;
    &::before {
      top: 2px;
      flex-shrink: 0;
      color: $secondary;
    }
  }
}

.header-inner-right {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-left: 1px solid $border-color;
}

.header-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  &__tel {
    height: 100%;
    padding: 9.5px 19px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 17.67px;
    font-family: $base-font-family-en;
    letter-spacing: 0.01em;
    @include media(lg) {
      height: 50%;
      padding: 12.5px 14px;
      font-size: 20px;
    }
    .icon {
      font-size: 18px;
      vertical-align: middle;
      @include media(lg) {
        font-size: 24px;
      }
      &::before {
        margin: 0 !important;
      }
    }
    span {
      display: none;
      @include media(lg) {
        display: inline;
      }
    }
  }
  &__search {
    display: none;
    @include media(lg) {
      height: 50%;
      padding: 12.5px 16px;
      display: flex;
      align-items: center;
      background: $natural-95;
      input[type='text'] {
        font-size: 14px;
        &::placeholder {
          color: $placeholder;
        }
      }
      .searchbox-inner {
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 24px;
        vertical-align: middle;
        &::before {
          margin: 0 4px 0 0 !important;
          top: -0.04em;
          position: relative;
        }
      }
    }
  }
  @include media(lg) {
    display: none;
  }
  @include media(xl) {
    width: 224px;
    display: flex;
  }
}
