@charset 'UTF-8';

@use '../global/' as *;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  line-height: $heading-line-height;
  margin-top: 0;
  letter-spacing: $heading-letter-spacing;
  text-rendering: optimizeLegibility;
}

h1,
.heading01 {
  font-size: $h1-font-size;
  font-weight: bold;
  margin-bottom: $h1-margin-bottom;
  &:not(:first-child) {
    margin-top: $h1-margin-top;
  }
  @include media(lg) {
    margin-bottom: 64px;
    @include fontsize(36);
  }
}

h2,
.heading02 {
  font-size: $h2-font-size;
  font-weight: normal;
  margin-bottom: $h2-margin-bottom;
  &:not(:first-child) {
    margin-top: $h2-margin-top;
  }
  @include media(lg) {
    margin-bottom: 28px;
    @include fontsize(29);
  }
}

h3,
.heading03 {
  font-size: $h3-font-size;
  font-weight: normal;
  margin-bottom: $h3-margin-bottom;
  line-height: 1.75;
  &:not(:first-child) {
    margin-top: $h3-margin-top;
  }
  @include media(lg) {
    margin-bottom: 23px;
    @include fontsize(23);
  }
}

h4,
.heading04 {
  font-size: $h4-font-size;
  font-weight: normal;
  margin-bottom: $h4-margin-bottom;
  line-height: 1.75;
  &:not(:first-child) {
    margin-top: $h4-margin-top;
  }
  @include media(lg) {
    @include fontsize(18);
  }
}

h5,
.heading05 {
  font-size: $h5-font-size;
  margin-bottom: $h5-margin-bottom;
  line-height: 1.75;
  &:not(:first-child) {
    margin-top: $h5-margin-top;
  }
}

h6,
.heading06 {
  font-size: $h6-font-size;
  margin-bottom: $h6-margin-bottom;
  line-height: 1.75;
  &:not(:first-child) {
    margin-top: $h6-margin-top;
  }
}

// heading
h1.heading,
.heading01 {
  padding: 0 20px 20px;
  color: $primary;
  border-bottom: 1px solid $border-color;
  @include media(lg) {
    padding: 0 0 36px;
  }
}
h2.heading,
.heading02 {
  padding-left: 10px;
  border-left: 3px solid $secondary;
  @include media(lg) {
    padding-left: 18px;
  }
}
// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  & + & {
    margin-top: $lead-top-spacer;
  }
}

// フォントファミリーを日本語に限定する（※）
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}

// code
pre {
  background-color: $gray-90;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    background-color: transparent;
    color: $gray-30;
    font-family: 'SourceHanCodeJP-Regular', monospace;
    font-size: 0.8em;
    font-weight: bold;
    vertical-align: initial;
  }
}
