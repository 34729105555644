@charset 'UTF-8';

@use '../global/' as *;

.footer-wrapper {
  color: $subtle;
  position: relative;
  border-top: 1px solid $border-strong;
  @include fontsize(14);
  a {
    color: $subtle;
    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.footer-container {
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include media(lg) {
    padding: 64px 56px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
  }
}

.footer-info {
  @include media(lg) {
    width: 20%;
  }
  .footer-logo {
    width: 230px;
    margin-bottom: 32px;
    line-height: 0;
    @include media(lg) {
      width: 100%;
      max-width: 258px;
      margin-bottom: 24px;
    }
  }
  p {
    & + p {
      margin-top: 11px;
    }
  }
}

.footer-nav {
  border-top: 1px solid $border-color;
  @include media(lg) {
    width: 70.7%;
    padding-left: 86px;
    border-left: 1px solid $border-color;
    border-top: none;
    flex: 1;
  }
  // navigation
  .navigation-list {
    margin-bottom: 32px;
    .navigation-item {
      .js-trigger {
        cursor: pointer;
        &::after {
          font-family: $icon-font-family;
          color: $gray-60;
          font-size: 2.4rem;
        }
      }
      &.active {
        .js-trigger {
          &::after {
            content: $minus;
          }
        }
      }
      .icon:not(.js-trigger) {
        &::after {
          content: $chevron-right;
          font-family: $icon-font-family;
          color: $gray-60;
          font-size: 2.4rem;
        }
      }
      .icon {
        border-bottom: 1px solid $border-color;
      }
    }
    .navigation-link,
    .navigation-category {
      padding: 17px 0;
      border-bottom: 1px solid $border-color;
      &::after {
        background-color: $border-color;
      }
      @include media(lg) {
        padding: 13px 0;
      }
    }
    @include media(lg) {
      margin-bottom: 40px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 12px;
      align-items: flex-start;
    }
  }

  // footer-menu
  .footer-menu {
    display: flex;
    gap: 23px;
    @include fontsize(13);
    @include media(lg) {
      gap: 33px;
      @include fontsize(14);
    }
  }
}

.footer-bnr {
  width: 100%;
  border-bottom: 1px solid $border-color;
  @include media(lg) {
    border-top: 1px solid $border-color;
  }
  &-list {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 32px;
    li {
      width: calc(50% - 4px);
      img {
        width: 100%;
      }
    }
    @include media(lg) {
      max-width: calc(248px * 4 + 24px * 3);
      margin: 0 auto;
      padding-top: 40px;
      padding-bottom: 40px;
      gap: 24px;
      align-items: center;
      li {
        width: 248px;
      }
    }
  }
}

.footer-copyright {
  width: 100%;
  @include fontsize(13);
}
