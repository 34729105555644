@charset 'UTF-8';

@use '../global/' as *;

.burger-wrapper {
  width: 56px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $primary;
  @include media(lg) {
    width: 98px;
  }
}

.burger-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  span {
    background-color: $burger-border-color;
    display: block;
    width: 100%;
    height: $burger-border-thickness; // 太さ
    transition: transform $base-transition, opacity $base-transition;
  }
  @each $key, $value in $burger-border-width {
    @include media($key, true) {
      width: $value;
      // ボーダー同士の間隔
      span:nth-of-type(2) {
        margin: map-get($burger-border-gutters, $key) 0;
      }
    }
  }

  // OPEN
  #{$burger-open-class} & {
    z-index: 3;
    background-color: transparent;
    span {
      background-color: $white;
      &:nth-of-type(1) {
        transform: translateY(5px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-5px) rotate(45deg);
      }
    }
    @include media(lg) {
      span {
        &:nth-of-type(1) {
          transform: translateY(7px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-7px) rotate(45deg);
        }
      }
    }
  }
}
