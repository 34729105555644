@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.closure-section-container {
  & + & {
    margin-top: 40px;
    @include sys.media(lg) {
      margin-top: 72px;
    }
  }
}

.closure-section-name {
  font-size: g.$h2-font-size;
  font-weight: normal;
  margin-bottom: g.$h2-margin-bottom;
  padding: 18px 20px;
  border-top: 1px solid g.$border-color;
  border-bottom: 1px solid g.$border-color;
  &:not(:first-child) {
    margin-top: g.$h2-margin-top;
  }
  @include sys.media(lg) {
    font-size: 2.4rem;
    margin-bottom: 32px;
    padding: 24px 0;
  }
}

.closure-item-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 100%;
    padding: 0.5em;
    @include sys.media(md) {
      width: 50%;
    }
    @include sys.media(lg) {
      width: 33.33333%;
    }
  }
}

.closure-item-container {
  border: 1px solid g.$border-color;
  padding: 8px;
  height: 100%;
  text-align: center;
  border-radius: 8px;
  @include sys.media(lg) {
    padding: 16px;
  }

  &._closure {
    .closure-title {
      background-color: sys.$closure-title-bg-color;
    }
  }
  &._assist {
    .closure-title {
      background-color: sys.$assist-title-bg-color;
    }
  }
}

.closure-title {
  background-color: g.$gray-20;
  color: g.$white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 4px;
  border-radius: 3px;
  @include sys.media(lg) {
    font-size: 1.5rem;
  }
}

.closure-date {
  margin: 10px auto;
  font-size: 1.5rem;
  color: g.$body-text-60;
}

.closure-timezone {
  margin-bottom: 10px;
  span {
    padding: 2px 8px;
    display: inline-block;
    font-size: 1.7rem;
    &.timezone_1 {
      background-color: g.$bg-hovered;
    }
    &.timezone_2 {
      background-color: g.$secondary-95;
    }
    &.timezone_3 {
      background-color: g.$natural-95;
    }
  }
}

.closure-doctor-container {
  display: flex;
  justify-content: center;
}

.closure-doctor {
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 10px;
  & + .closure-doctor:before {
    content: '→';
    display: inline-block;
    padding: 0 5px;
  }
}

.closure-comment {
  font-size: 1.5rem;
  color: g.$error;
}
