@charset 'UTF-8';

$icon-font-family: 'potaicon' !default;
$icon-font-path: '/assets/fonts/icon' !default;

$c-pankuzu-arrow: '\e922';
$c-search: '\e91f';
$c-neurology: '\e921';
$c-circle-chevron: '\e932';
$c-walking: '\e933';
$c-groups-perspective: '\e934';
$c-calendar: '\e935';
$c-referral: '\e936';
$c-doctor: '\e951';
$c-cardiovascular: '\e938';
$c-schedule_datetime: '\e939';
$c-group-discussion: '\e93a';
$c-ophthalmology: '\e93b';
$c-urology: '\e93c';
$c-cardiology: '\e93d';
$c-otolaryngology: '\e93e';
$c-multiple-choice: '\e93f';
$c-doctor-male: '\e940';
$c-neuro-surgery: '\e941';
$c-neurosurgery: '\e942';
$c-masui: '\e944';
$c-body: '\e945';
$c-baby: '\e946';
$c-dermatology: '\e947';
$c-hospital-bed: '\e948';
$c-rehabilitation: '\e949';
$c-ct-scan: '\e94a';
$c-home: '\e94b';
$c-notes: '\e94c';
$c-internal-medicine: '\e94d';
$c-surgery: '\e94f';
$c-orthopaedic: '\e950';
$c-info: '\e920';
$c-alert-circle: '\e943';
$headset: '\e91e';
$tree: '\e923';
$search: '\e924';
$quiz: '\e925';
$map: '\e926';
$map-pin: '\e927';
$home: '\e928';
$calendar: '\e929';
$datetime: '\e92d';
$circle-phone: '\e92e';
$document: '\e92f';
$pdf: '\e930';
$warning-outline: '\e931';
$caution: '\e900';
$caution-outline: '\e901';
$warning: '\e902';
$phone: '\e903';
$mail: '\e904';
$plus: '\e905';
$add: '\e906';
$add-outline: '\e907';
$clear: '\e908';
$minus: '\e909';
$remove: '\e90a';
$remove-outline: '\e90b';
$chevron-down: '\e90c';
$chevron-left: '\e90d';
$chevron-right: '\e90e';
$chevron-up: '\e90f';
$smart-phone: '\e910';
$radio: '\e911';
$arrow-left: '\e912';
$caret-down: '\e913';
$caret-up: '\e914';
$arrow-right: '\e915';
$arrow-up: '\e916';
$arrow-down: '\e917';
$caret-left: '\e918';
$caret-right: '\e919';
$arrow-back: '\e92b';
$arrow-forward: '\e92c';
$checkbox-checked: '\e91a';
$checkbox: '\e91b';
$radio-checked: '\e91c';
$open-new-window: '\e91d';
$help: '\e94e';
$delete: '\e92a';
