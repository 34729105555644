@charset 'UTF-8';

@use '../global/' as *;

.copyright-wrapper {
  background-color: $base;
  flex: none;
  padding: 0.6em 0;
  position: relative;
  width: 100%;
  z-index: $z-index-footer;
  @include media(md) {
    padding: 0.6em 0;
  }
  @include media(lg) {
    padding: 0.6em 0;
  }
}

.copyright-container {
  @include makeContainer;
}

.copyright {
  color: $white;
  font-size: 1rem;
  text-align: center;
}
