@charset 'UTF-8';

@use '../global/' as *;

// variable
$accordion-open-class-name: '.active';

.accordion-wrapper {
  width: 100%;
}

.accordion {
  position: relative;
  background-color: transparent;
  transition: background-color $base-transition;
  & + .accordion {
    border-top: 1px solid $border-color;
  }
  @media (hover: hover) {
    &:hover {
      background-color: $bg-default;
    }
  }
}

.accordion-heading {
  width: 100%;
  margin-bottom: 0;
  padding: 16px 34px 16px 4px;
  position: relative;
  border-radius: 8px 8px 0 0;
  @include fontsize(18);
  cursor: pointer;
  @include media(lg) {
    padding: 24px 34px 24px 4px;
  }
  // arrow
  &::before,
  &::after {
    width: 2px;
    height: 12px;
    position: absolute;
    right: 17px;
    top: calc(50% - 7px);
    background-color: $base;
    transition: transform $base-transition;
    content: '';
    @include media(lg) {
      right: 16px;
    }
  }
  &::before {
    transform: rotate(90deg);
  }
  &#{$accordion-open-class-name} {
    padding: 16px 20px;
    background-color: $bg-default;
    @include media(lg) {
      padding: 24px;
    }
    &::before,
    &::after {
      right: 29px;
      @include media(lg) {
        right: 32px;
      }
    }
    &::after {
      transform: rotate(90deg);
    }
    & + .accordion-contents {
      background-color: $bg-default;
    }
  }
}

.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height $base-transition;
  border-radius: 0 0 8px 8px;
}

.accordion-contents-inner {
  margin: 0 20px;
  padding: 4px 0 35px;
  @include media(lg) {
    margin: 0 24px;
    padding-top: 0;
  }
}
