@charset 'UTF-8';

@use '../global/' as *;

.frame-border {
  background-color: $white;
  border: 1px solid $gray-dark;
  padding: 10px;
}

.frame-mat {
  background-color: $gray-90;
  padding: 10px;
}
